import React, { createContext, useEffect, useState } from "react";
import { fetchFeatureFlags } from "../store/featureFlagSlice";
import { StatusObj } from "../utils";
import Loading from "../components/Loading";

export interface FilterOptModel {
  label: string;
  value: string | number;
  key: string;
  storage: boolean;
}

export interface ColumnModel {
  dbPropertyName: string;
  label: string;
  isSortable: boolean;
  isSearchable: boolean;
  displayOrder: number;
  renderType: string;
  showIfStatus?: string;
  width?: string;
}

export interface DynamicDataInfoModel {
  dataKey: string;
  dataLabel: string;
  dataType: string;
}

export interface PageLayout {
  useDefaultLayout: boolean;
  useCustomHeader: boolean;
  displayEmailList: boolean;
  controlsSize: string;
}

export interface StatusRename {
  status: string;
  displayAs: string;
}

export interface DocTypeRename {
  docType: string;
  displayAs: string;
}

export interface DetailPageSection {
  key: string;
  title: string;
  collapsible: boolean;
}

export interface FeatureFlag {
  signalrHubName: string;
  filterOptions: FilterOptModel[];
  defaultSortingColumn: string;
  displayColumns: ColumnModel[];
  filterStatusOptions: StatusObj[];
  dynamicDataInfo: DynamicDataInfoModel[];
  isFullAccessRoles: boolean;
  IsDetailsMultiFile: boolean;
  isGlobalSearchBox: boolean;
  isAssignToEnabled: boolean;
  showCheckedStatus: string[];
  showFailedStatus: string[];
  fullAccessRolesNames: string[];
  showPotentialDuplicates: boolean;
  isCheckPotentialDuplicated: boolean;
  detectDuplicateBeforeSubmit: boolean;
  duplicateDetectionFields: string[];
  detailPageSections: DetailPageSection[];
  invoiceTotalValue: {
    fields: string[];
    taxFields: string[];
    expression: string;
  };
  isSubmitRestrictedByStatus: boolean;
  disabledSubmitStatuses: string[];
  pageLayout: PageLayout;
  statusRename: StatusRename[];
  docTypeRename: DocTypeRename[];
  allowResubmitReasonField: string;
  allowResubmitReasonValue: string[];
  highlightEmptyFields: boolean;
  disableStateStatus: string[];
  isCloneInvoiceEnabled: boolean;
  invoiceCostVariancePercentage: number;
  overrideVarianceInvoiceAmount: boolean;
  cloneInvoiceStatus: string[];
  useStatusIcons: boolean;
  isReprocessSplitDocumentEnabled: boolean;
  showServiceTeamDropdownSearch: boolean;
  addOriginalSenderStatusAvailable: string[];
  addEditorEmailToTags: string[];
  isCustomerMappingEnabled: boolean;
  showAttachmentPopover: boolean;
  presenceLock: boolean;
  hideStatusPercentageInBuckets: boolean;
  amountIndicatorTolerance: number;
  enableAuditLogging: boolean;
}

const detailPageSectionsDefault = [
  {
    key: "AttachmentMetadata",
    title: "",
    collapsible: false,
  },
  {
    key: "EmailBody",
    title: "Email Body",
    collapsible: true,
  },
  {
    key: "PotentialDuplicates",
    title: "",
    collapsible: false,
  },
  {
    key: "InputFields",
    title: "",
    collapsible: false,
  },
  {
    key: "AdditionalDocuments",
    title: "",
    collapsible: false,
  },
];

export const FeatureFlags = createContext(null);

export const FeatureFlagProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [features, setFeatures] = useState<FeatureFlag | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const data = (await fetchFeatureFlags()) as FeatureFlag;
        if (data) {
          const featureFlags: FeatureFlag = {
            signalrHubName: data.signalrHubName ?? "",
            pageLayout: {
              useDefaultLayout: data.pageLayout?.useDefaultLayout ?? false,
              useCustomHeader: data.pageLayout?.useCustomHeader ?? false,
              displayEmailList: data.pageLayout?.displayEmailList ?? false,
              controlsSize: data.pageLayout?.controlsSize ?? "large",
            },
            showPotentialDuplicates: data.showPotentialDuplicates ?? false,
            isCheckPotentialDuplicated: data.isCheckPotentialDuplicated ?? false,
            detectDuplicateBeforeSubmit: data.detectDuplicateBeforeSubmit ?? false,
            duplicateDetectionFields: data.duplicateDetectionFields ?? [],
            detailPageSections: data.detailPageSections ?? detailPageSectionsDefault,
            defaultSortingColumn: data.defaultSortingColumn ?? "",
            filterOptions: data.filterOptions ?? [],
            filterStatusOptions: data.filterStatusOptions ?? [],
            displayColumns: data.displayColumns ?? [],
            dynamicDataInfo: data.dynamicDataInfo ?? [],
            isFullAccessRoles: data.isFullAccessRoles ?? false,
            IsDetailsMultiFile: data.IsDetailsMultiFile ?? false,
            isGlobalSearchBox: data.isGlobalSearchBox ?? false,
            isAssignToEnabled: data.isAssignToEnabled ?? false,
            showCheckedStatus: data.showCheckedStatus ?? [],
            showFailedStatus: data.showFailedStatus ?? [],
            fullAccessRolesNames: data.fullAccessRolesNames ?? [],
            invoiceTotalValue: data.invoiceTotalValue ?? null,
            isSubmitRestrictedByStatus: data.isSubmitRestrictedByStatus ?? false,
            disabledSubmitStatuses: data.disabledSubmitStatuses ?? [],
            statusRename: data.statusRename ?? [],
            docTypeRename: data.docTypeRename ?? [],
            allowResubmitReasonField: data.allowResubmitReasonField ?? "",
            allowResubmitReasonValue: data.allowResubmitReasonValue ?? [],
            highlightEmptyFields: data.highlightEmptyFields ?? false,
            disableStateStatus: data.disableStateStatus ?? [],
            isCloneInvoiceEnabled: data.isCloneInvoiceEnabled ?? false,
            invoiceCostVariancePercentage: data.invoiceCostVariancePercentage ?? 2,
            overrideVarianceInvoiceAmount: data.overrideVarianceInvoiceAmount ?? false,
            cloneInvoiceStatus: data.cloneInvoiceStatus ?? [],
            useStatusIcons: data.useStatusIcons ?? false,
            isReprocessSplitDocumentEnabled: data.isReprocessSplitDocumentEnabled ?? false,
            showServiceTeamDropdownSearch: data.showServiceTeamDropdownSearch ?? false,
            addOriginalSenderStatusAvailable: data.addOriginalSenderStatusAvailable ?? [],
            addEditorEmailToTags: data.addEditorEmailToTags ?? [],
            isCustomerMappingEnabled: data.isCustomerMappingEnabled ?? false,
            showAttachmentPopover: data.showAttachmentPopover ?? false,
            presenceLock: data.presenceLock ?? false,
            hideStatusPercentageInBuckets: data.hideStatusPercentageInBuckets ?? false,
            amountIndicatorTolerance: data.amountIndicatorTolerance ?? 0,
            enableAuditLogging: data.enableAuditLogging ?? false,
          };
          setFeatures(featureFlags);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return <FeatureFlags.Provider value={{ features }}>{isLoading ? <Loading /> : children}</FeatureFlags.Provider>;
};
