import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

import { RootState } from ".";
import { mapStatusCounts, ObjectToQueryParam, StatusCounts } from "../utils";

import { fetchEmail, fetchInvoices, setPotentialDuplicates } from "./emailsSlice";
import { notification } from "antd";

const multipartFormDataHeader = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
};

export const fetchAllInvoices = createAsyncThunk("invoices/fetch/all",
  async ({ filter, controller, refresh = false }: { filter?: { search?: string, status?: string, sortColumn?: string, sortDirection?: string; }, controller, refresh?: boolean; }, { getState }) => {
    const queryParams = ObjectToQueryParam(filter);

    //@ts-ignore
    const prevQueryParams = getState().invoices.queryParams;

    if (!refresh && prevQueryParams === queryParams) {
      return Promise.reject();
    }

    if (window.history.pushState) {
      let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      if (queryParams) {
        newurl += `?${queryParams}`;
        window.history.pushState({ path: newurl }, "", newurl);
      }
    }

    try {
      const response = await axios.get(`/invoices?${queryParams}`, { signal: controller.signal });
      if (response.status === 200) {
        return { response: response.data, queryParams };
      } else {
        console.error(`Http Error! Status: ${response.status}`);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error(error);
      }
    }
  }
);

export const fetchInvoice = createAsyncThunk("invoice/fetch", async (id: string) => {
  return axios.get(`/invoices/${id}`).then((res) => res.data);
});

export const fetchRelatedAttachments = createAsyncThunk(
  "related-attachments/fetch",
  async (relatedAttachmentIds: string[]) => {
    return axios.post("/related-attachments", relatedAttachmentIds)
      .then((res) => res.data);
  }
);

export const fetchOptions = async (url: string): Promise<any[]> => {
  return axios.get(url).then((res) => res.data);
};

export const approveInvoice = createAsyncThunk("invoice/approve", async (id: string) => {
  return axios.put(`/invoices/${id}/approve`).then((res) => res.data);
});

export const bulkStatusUpdate = createAsyncThunk("invoice/bulkStatusUpdate", async ({ ids, status }: { ids: Array<string>; status: string; }) => {
  return axios.put(`/invoices/bulkStatusUpdate`, { ids, status }).then((res) => res.data);
});

export const updateInvoice = createAsyncThunk(
  "invoice/update",
  async ({ id, finalizedData, ignore, approveAllFlag = false }: { id: string; finalizedData: FinalizedData; ignore: boolean, approveAllFlag?: boolean; }) => {
    return axios.put(`/invoices/${id}?forceDuplicate=${ignore}&approveAll=${approveAllFlag}`, finalizedData).then((res) => res.data);
  }
);

export const checkPotentialDuplicate = createAsyncThunk(
  "invoice/checkPotentialDuplicate",
  async (
    { attachmentId, finalizedData }: { attachmentId: string; finalizedData: FinalizedData; },
    { dispatch }
  ) => {
    const response = await axios.put(`/invoices/${attachmentId}/potentialDuplicate`, finalizedData);
    const duplicates = response.data;
    dispatch(setPotentialDuplicates({ attachmentId, duplicates }));
    return duplicates;
  }
);

export const checkOrderAmountFromSalesforce = createAsyncThunk(
  "invoice/checkOrderAmountFromSalesforce",
  async ({ id, finalizedData }: { id: string; finalizedData: FinalizedData; }) => {
    return axios.put(`/invoices/${id}/orderAmountSalesforce`, finalizedData).then((res) => res.data);
  }
);

export const cloneInvoice = createAsyncThunk("invoice/clone",
  async ({ id }: { id: string; }) => {
    return axios.post(`/invoices/${id}/clone`, null).then((res) => res.data);
  }
);

export const fetchInvoiceCounts = createAsyncThunk("invoices/counts",
  async ({ controller }: { controller; }, { getState }) => {

    try {
      const response = await axios.get(`/invoices/counts`, { signal: controller.signal });
      if (response.status === 200) {
        return response.data;
      } else {
        console.error(`Http Error! Status: ${response.status}`);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error(error);
      }
    }
  });

export const fetchSalesForceFields = createAsyncThunk("salesForceFields/fetch", async (_, { getState }) => {
  //@ts-ignore
  const state: RootState = getState();
  if (state.invoices.salesForceFields?.length) {
    return Promise.reject();
  }
  return axios.get("/ocr-config/field-mappings").then((res) => res.data.SalesforceFields);
});

export const uploadAdditionalDocuments = createAsyncThunk("invoices/upload", async ({ id, formData }: { id: string; formData: FormData; }) => {
  return axios.post(`/invoices/${id}/upload`, formData, multipartFormDataHeader).then((res) => res.data);
});

export const uploadNewDocumentVersion = createAsyncThunk("invoices/uploadnewversion", async ({ id, formData }: { id: string; formData: FormData; }) => {
  return axios.post(`/invoices/${id}/uploadnewversion`, formData, multipartFormDataHeader).then((res) => res.data);
});

export const splitDocument = createAsyncThunk(
  'invoices/splitInvoice',
  async ({ reProcess, pageRange, attachment }: { reProcess: boolean; pageRange: string; attachment: Attachment; }, { dispatch }) => {
    try {
      await axios.post(`/invoices/${attachment.id}/split`, { reProcess, pageRange });
    } finally {
      dispatch(fetchInvoice(attachment.id));
      dispatch(fetchEmail(attachment.emailId));
      dispatch(fetchInvoices(attachment.emailId));
      dispatch(fetchSalesForceFields());
    }
  }
);

// Then, handle actions in your reducers:
const invoicesSlice = createSlice({
  name: "invoices",
  initialState: {
    loading: false,
    loadingRelatedAttachments: false,
    salesForceFields: [] as SalesForceField[],
    data: [] as Attachment[],
    queryParams: "",
    current: { finalizedData: {} } as Attachment,
    statusCounts: {} as StatusCounts[],
    totalCount: 0,
    page: 1,
    historical: false,
    originalStatus: "",
    connection: null,
    error: false
  },
  reducers: {
    setInvoiceData(state, action: PayloadAction<FinalizedData>) {
      return {
        ...state,
        current: {
          ...state.current,
          finalizedData: {
            ...action.payload,
            documents: [...state.current.finalizedData.documents]
          }
        }
      };
    },
    resetCurrentAttachment(state) {
      return { ...state, current: { finalizedData: {} } as Attachment };
    },
    resetRelatedAttachments(state) {
      return { ...state, current: { ...state.current, finalizedData: { ...state.current.finalizedData, relatedAttachments: [] as Attachment[] } } as Attachment };
    },
  },
  extraReducers: {
    [fetchAllInvoices.fulfilled.type]: (state, action) => {
      if (action.payload) {
        state.data = action.payload.response.data;
        state.page = action.payload.response.page;
        state.totalCount = action.payload.response.totalCount;
        state.queryParams = action.payload.queryParams;
        state.historical = action.payload.historical;
        state.loading = false;
        state.error = false;
      } else if (action.meta.arg.filter) {
        state.data = [];
        state.totalCount = 0;
        state.queryParams = '';
        state.loading = false;
        state.error = true;
      }
    },
    [fetchAllInvoices.pending.type]: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    [fetchAllInvoices.rejected.type]: (state, action) => {
      state.loading = false;
      state.error = false;
    },

    [splitDocument.pending.type]: (state, action) => {
      state.loading = true;
    },
    [splitDocument.fulfilled.type]: (state, action) => {
      state.loading = false;

      notification.open({
        message: "Document Split Done",
        description: "Document successfully split.",
      });
    },
    [splitDocument.rejected.type]: (state, action) => {
      state.loading = false;

      notification.open({
        message: "Error",
        description: "An error occurred while splitting document. Please try again.",
      });
    },

    [fetchRelatedAttachments.fulfilled.type]: (state, action) => {
      state.current.finalizedData.relatedAttachments = action.payload;
      state.loadingRelatedAttachments = false;
    },
    [fetchRelatedAttachments.pending.type]: (state, action) => {
      state.loadingRelatedAttachments = true;
    },
    [fetchRelatedAttachments.rejected.type]: (state, action) => {
      state.loadingRelatedAttachments = false;
    },

    [fetchInvoice.fulfilled.type]: (state, action) => {
      state.current = action.payload;
      state.loading = false;
    },
    [fetchInvoice.pending.type]: (state, action) => {
      state.loading = true;
    },
    [fetchInvoice.rejected.type]: (state, action) => {
      state.loading = false;
    },

    [updateInvoice.fulfilled.type]: (state, action) => {
      state.current = action.payload;
      state.loading = false;
    },
    [updateInvoice.pending.type]: (state, action) => {
      state.loading = true;
    },
    [updateInvoice.rejected.type]: (state, action) => {
      state.loading = false;
    },
    [checkPotentialDuplicate.fulfilled.type]: (state, action) => {
      if (state.current?.finalizedData) {
        state.current.finalizedData.potentialDuplicateInvoiceIds = action.payload;
      }
      state.loading = false;
    },
    [checkPotentialDuplicate.pending.type]: (state, action) => {
      state.loading = true;
    },
    [checkPotentialDuplicate.rejected.type]: (state, action) => {
      state.loading = false;
    },

    [cloneInvoice.fulfilled.type]: (state, action) => {
      state.loading = false;
    },
    [cloneInvoice.pending.type]: (state, action) => {
      state.loading = true;
    },
    [cloneInvoice.rejected.type]: (state, action) => {
      state.loading = false;
    },

    [approveInvoice.fulfilled.type]: (state, action) => {
      state.current.status = "Approved";
      state.loading = false;
    },
    [approveInvoice.pending.type]: (state, action) => {
      state.loading = true;
    },
    [approveInvoice.rejected.type]: (state, action) => {
      state.loading = false;
    },

    [fetchInvoiceCounts.fulfilled.type]: (state, action) => {
      state.statusCounts = mapStatusCounts(action.payload);
    },
    [fetchInvoiceCounts.pending.type]: (state, action) => { },
    [fetchInvoiceCounts.rejected.type]: (state, action) => { },

    [fetchSalesForceFields.fulfilled.type]: (state, action) => {
      state.salesForceFields = action.payload;
    },
    [fetchSalesForceFields.pending.type]: (state, action) => { },
    [fetchSalesForceFields.rejected.type]: (state, action) => { },
  },
});

export const { setInvoiceData, resetCurrentAttachment, resetRelatedAttachments } = invoicesSlice.actions;
export default invoicesSlice.reducer;

export interface Attachment {
  filename: string;
  emailId: string;
  id: string;
  finalizedData: FinalizedData;
  lastUpdatedAt: string;
  ocrID: string;
  status: Status;
  url: string;
  fileURL: string;
  presenceUsers: string[];
  docType: string;
  documents: [];

  _rid: string;
  _self: string;
  _etag: string;
  _attachments: string;
  _ts: number;
}

export type Status = "New" | "OCR Completed" | "Needs Review" | "Approved" | "Rejected" | "Sent To Salesforce" | "Salesforce Upload Processing" | "Salesforce Upload Complete" | "Skipped" | "Split Processed" | "Salesforce Upload Failure";

export interface OcrData {
  error?: {
    message: string;
  };
  status: string;
  createdDateTime: string;
  lastUpdatedDateTime: string;
  analyzeResult: AnalyzeResult;
}

export interface AnalyzeResult {
  version: string;
  readResults: ReadResult[];
  pageResults: PageResult[];
  documentResults: DocumentResult[];
}

export interface DocumentResult {
  docType: string;
  pageRange: number[];
  fields: { [key: string]: Field | null; };
}

export interface Field {
  type: Type;
  valueString?: string;
  text: string;
  boundingBox: number[];
  page: number;
  confidence: number;
  valueDate?: string;
  valueNumber?: number;
}

export enum Type {
  Date = "date",
  Number = "number",
  String = "string",
}

export interface PageResult {
  page: number;
  tables: Table[] | null;
}

export interface Table {
  rows: number;
  columns: number;
  cells: Cell[];
  boundingBox: number[];
}

export interface Cell {
  rowIndex: number;
  columnIndex: number;
  text: string;
  boundingBox: number[];
  columnSpan: number | null;
}

export interface ReadResult {
  page: number;
  angle: number;
  width: number;
  height: number;
  unit: string;
}

interface CascadeOptions {
  [key: string]: string[];
}

export interface SalesForceField {
  id: string;
  relatedField?: string;
  enableIfField: string;
  enableIfField2: string;
  enableIfValue: string;
  enableIfValue2: string;
  enableIfRequired: 0 | 1;
  enableIfRequired2: 0 | 1;
  name: string;
  ocrField: null | string;
  type: string;
  default: null | string;
  dropdownCascadeOnSaved: string;
  dropdownCascade: string;
  dropdownOptions: string[] | CascadeOptions;
  defaultIssuesEmailTags: string[];
  listIssuesEmailTags: string[];
  defaultRejectEmailTags: string[];
  listRejectEmailTags: string[];
  lookupAdditionalFields: null | boolean;
  lookupColumn: null | string;
  lookupField: null | string;
  lookupURL: null | string;
  InvoiceId?: null | string;
  value?: null | string;
  required: 0 | 1;
  readOnly?: 0 | 1;
  readOnlyOnField?: string;
  readOnlyOnFieldValue?: string;
  minRows?: number;
  maxRows?: number;
}

export interface FinalizedData {
  assetName?: string;
  billingPostalCode?: string;
  currency?: string;
  customerName?: string;
  docType: string;
  originalAttachmentId: string;
  duplicateNumber: number;
  versions: string[];
  documents: string[];
  emailFrom?: string;
  emailSubject: string;
  invoiceAmount?: number;
  invoiceDate?: string;
  invoiceNumber?: string;
  issuesEmail?: string;
  issuesReason?: string;
  issuesReasonOther?: string;
  lastUpdatedAt: Date;
  notes?: string;
  ocrReceived?: Date;
  orderNumber?: string;
  pages: string[];
  plainFilename?: string;
  poNumber?: string;
  potentialDuplicateInvoiceIds?: string[];
  processedAt: Date;
  processedBy: string;
  processedByName: string;
  rejectedEmail?: string;
  rejectedReason?: string;
  serviceDate: Date;
  serviceTeam?: string;
  status: string;
  statusLastChangeAt?: Date;
  tax?: string;
  vendor?: string;
  vendorId?: string;
  vendorName?: string;
  vendorWorkOrderNumber?: string;
  workOrderNumber?: string;
  workOrderNumberWithPrefix: string;
  relatedAttachmentsIds?: string[];
  relatedAttachments?: Attachment[];
  asset?: string;
  assetLocation?: string;
  invoiceTotalCalculated?: string;
}

export interface PotentialDuplicate {
  attachmentId: string;
  duplicates: string[];
}

export enum StatusEnum {
  New = 1,
  NeedsReview,
  CorrectionsNeeded,
  Approved,
  Rejected,
  Submitted,
  OcrFailure,
  SenttoSalesforce,
  PermenantRejection,
}
